import React, {Component} from "react"
import styles from "./LogoutButton.module.css"

import {ReactComponent as LogoutIcon} from "./img/logout.svg"

interface Props {
    onClick: (e?) => void
}

export class LogoutButton extends Component<Props, {}> {
    public render() {
        return (
            <button className={styles.root} onClick={this.props.onClick}>
                Log out
                <LogoutIcon className={styles.icon} width="16" height="15" />
            </button>
        )
    }
}
