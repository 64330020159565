import React, {Component} from "react"
import styles from "./HelpButton.module.css"

import {ReactComponent as HelpIcon} from "./img/help.svg"

interface Props {
    onClick: (e?) => void
}

export class HelpButton extends Component<Props, {}> {
    public render() {
        return (
            <button className={styles.root} onClick={this.props.onClick}>
                <HelpIcon className={styles.icon} width="20" height="20" />
                Help
            </button>
        )
    }
}
