export const json = {
    campuses: [
        {
            name: "Undecided",
            code: "undecided",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["undecided"]
        },
        {
            name: "ANU - Roanoke Valley, VA",
            code: "RKE",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["PBY-ECG17", "undecided"]
        },
        {
            name: "ANU - Louisville, KY",
            code: "LOU",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Virgie Douglas - DA",
                    email: "vdouglas@an.edu"
                },
                {
                    name: "Austin Wingate",
                    email: "awingate@an.edu"
                },
                {
                    name: "Jeremy Buttil",
                    email: "jbuttil@an.edu"
                },
                {
                    name: "Tyshane’ Simms",
                    email: "tsimms@an.edu"
                },
                {
                    name: "Patrick Williford",
                    email: "patwilliford@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["HIM17", "MAA17", "PBY-ECG17", "MAA-C", "PLGC", "AMAC", "undecided"]
        },
        {
            name: "ANU - Pikeville, KY",
            code: "PVL",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Patricia Bailey",
                    email: "pbailey@an.edu"
                },
                {
                    name: "Madison Varney",
                    email: "mvarney@an.edu"
                },
                {
                    name: "Stevee Ferrell",
                    email: "sferrell@an.edu"
                },
                {
                    name: "Lila Kelly",
                    email: "lkelly@an.edu"
                },
                {
                    name: "Valerie Murray",
                    email: "vmurray@an.edu"
                },
                {
                    name: "Iva Conway",
                    email: "iconway@an.edu"
                },
                {
                    name: "Kairee Feig",
                    email: "kfeig@an.edu"
                },
                {
                    name: "Samantha Hall",
                    email: "samhall@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["ADN", "MAA17", "PBY-ECG17", "MAA-C", "PLGC", "AMAC", "undecided"]
        },
        {
            name: "ANU - Distance Education Division",
            code: "???",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Timothy Bond",
                    email: "tbond@an.edu"
                },
                {
                    name: "Laterrica Smith",
                    email: "lsmith@an.edu"
                },
                {
                    name: "Sheritha Smith",
                    email: "shersmith@an.edu"
                },
                {
                    name: "Jamie Stewart",
                    email: "jcstewart@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Susan Morgan",
                    email: "smorgan@an.edu"
                },
                {
                    name: "Rebecca Harris",
                    email: "rharris@an.edu"
                },
                {
                    name: "Ashleigh Killiebrew",
                    email: "akilliebrew@an.edu"
                },
                {
                    name: "Tonya Elmore",
                    email: "telmore@an.edu"
                },
                {
                    name: "Virgie Douglas",
                    email: "vdouglas@an.edu"
                },
                {
                    name: "Austin Wingate",
                    email: "awingate@an.edu"
                },
                {
                    name: "Andria Ivory",
                    email: "aivory@an.edu"
                },
                {
                    name: "Yolanda Edwards",
                    email: "yedwards@an.edu"
                },
                {
                    name: "Raya Banks",
                    email: "rdbanks@an.edu"
                },
                {
                    name: "Becki Sessions",
                    email: "bsessions@an.edu"
                },
                {
                    name: "Kiara Taylor ",
                    email: "kmtaylor@an.edu"
                },
                {
                    name: "Lawrence Gilbert",
                    email: "lgilbert@an.edu"
                },
                {
                    name: "Patricia Bailey",
                    email: "pbailey@an.edu"
                },
                {
                    name: "Madison Varney",
                    email: "mvarney@an.edu"
                },
                {
                    name: "Alesha Slaughter",
                    email: "aslaughter@an.edu"
                },
                {
                    name: "Molly Green",
                    email: "mgreen@an.edu"
                },
                {
                    name: "Nicolette McCarthy",
                    email: "nmccarthy@an.edu"
                },
                {
                    name: "Kenesha Parham",
                    email: "kparham@an.edu"
                },
                {
                    name: "Brittany White",
                    email: "bwhite@an.edu"
                },
                {
                    name: "Stevee Ferrell",
                    email: "sferrell@an.edu"
                },
                {
                    name: "Lila Kelly",
                    email: "lkelly@an.edu"
                },
                {
                    name: "Jeremy Buttil",
                    email: "jbuttil@an.edu"
                },
                {
                    name: "Tyshane’ Simms",
                    email: "tsimms@an.edu"
                },
                {
                    name: "Iva Conway",
                    email: "iconway@an.edu"
                },
                {
                    name: "Kairee Feig",
                    email: "kfeig@an.edu"
                },
                {
                    name: "Jessica Young",
                    email: "jyoung@an.edu"
                },
                {
                    name: "Ann-Marie Langlois",
                    email: "alanglois@an.edu"
                },
                {
                    name: "Phillip Lino",
                    email: "plino@an.edu"
                },
                {
                    name: "Isaiah Isaac",
                    email: "iisaac@an.edu"
                },
                {
                    name: "Kylee Beecher",
                    email: "kbeecher@an.edu"
                },
                {
                    name: "Samantha Hall",
                    email: "samhall@an.edu"
                },
                {
                    name: "Patrick Williford",
                    email: "patwilliford@an.edu"
                }
            ],
            onlinePrograms: [
                "MBA",
                "MSC",
                "MSIT",
                "BBACC",
                "BBAMG",
                "CSYB",
                "NETB",
                "MHSM18",
                "RN-BSN",
                "BAA",
                "MGT",
                "NET-A",
                "HIM17",
                "PTA-2018",
                "ACC",
                "BAM",
                "MAA17",
                "PBY-ECG17",
                "MAA-C",
                "PLG",
                "PLGC",
                // "CDL",
                "AMAA",
                "MCD17",
                "PTH-2018",
                "AMAC",
                "undecided"
            ],
            groundPrograms: []
        },
        {
            name: "ANU - Nashville, TN",
            code: "NVL",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Becki Sessions",
                    email: "bsessions@an.edu"
                },
                {
                    name: "Lawrence Gilbert",
                    email: "lgilbert@an.edu"
                },
                {
                    name: "Timothy Bond",
                    email: "tbond@an.edu"
                },
                {
                    name: "Sheritha Smith",
                    email: "shersmith@an.edu"
                },
                {
                    name: "Nicolette McCarthy",
                    email: "nmccarthy@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["MAA17", "PBY-ECG17", "MAA-C", "PLGC", "AMAC", "undecided"]
        },
        {
            name: "ANU - Bartlett, TN",
            code: "BRT",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "tramey@an.edu"
                },
                {
                    name: "Andre Davis",
                    email: "adavis@an.edu"
                },
                {
                    name: "Anne Snyde",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Patricia Mitchem",
                    email: "pmitchem@an.edu"
                },
                {
                    name: "Irene Eriaka",
                    email: "ieriakha@an.edu"
                },
                {
                    name: "Kenny Blankenship",
                    email: "krblankenship@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Lori Price",
                    email: "lprice@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Andria Ivory",
                    email: "aivory@an.edu"
                },
                {
                    name: "Raya Banks",
                    email: "rdbanks@an.edu"
                },
                {
                    name: "Jason Harris",
                    email: "jjharris@an.edu"
                },
                {
                    name: "Yolanda Edwards",
                    email: "yedwards@an.edu"
                },
                {
                    name: "Kiara Taylor ",
                    email: "kmtaylor@an.edu"
                },
                {
                    name: "Laterrica Smith",
                    email: "lsmith@an.edu"
                },
                {
                    name: "Kenesha Parham",
                    email: "kparham@an.edu"
                },
                {
                    name: "Brittany White",
                    email: "bwhite@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["MAA17", "PBY-ECG17", "MAA-C", "PLGC", "AMAC", "undecided"]
        }
    ],
    programs: [
        {
            code: "undecided",
            name: "Undecided"
        },
        {
            code: "AMAA",
            name: "Administrative Medical Assisting (Associates)"
        },
        {
            code: "AMAC",
            name: "Administrative Medical Assisting - Certification",
            value: "Admin Medical Assisting (Certificate)"
        },
        {
            code: "MBA",
            name: "Master of Business Administration"
        },
        {
            code: "MSC",
            name: "Master of Science in Cybersecurity"
        },
        {
            code: "MSIT",
            name: "Master of Information Technology"
        },
        {
            code: "TESM",
            name: "Master of Science in TESOL"
        },
        {
            code: "BBACC",
            name: "Business Administration - Accounting (Bachelor Program)"
        },
        {
            code: "BBAMG",
            name: "Business Administration - Management (Bachelor Program)"
        },
        {
            code: "CSYB",
            name: "Cybersecurity (Bachelor Program)"
        },
        {
            code: "NETB",
            name: "Network Administration (Bachelor Program)"
        },
        {
            code: "RN-BSN",
            name: "RN-BSN (RN to BSN Program)"
        },
        {
            code: "BAA",
            name: "Business Administration - Accounting (Associate Program)"
        },
        {
            code: "MGT",
            name: "Business Administration - Management (Associate Program)"
        },
        {
            code: "NET-A",
            name: "Network Support Associate Program"
        },
        {
            code: "ADN",
            name: "Nursing"
        },
        {
            code: "HIM17",
            name: "Health Information Management"
        },
        {
            code: "MAA17",
            name: "Medical Assisting Associate"
        },
        {
            code: "PLG",
            name: "Paralegal"
        },
        {
            code: "ACC",
            name: "Accounting (Diploma Program)"
        },
        {
            code: "BAM",
            name: "Business Administration (Diploma Program)"
        },
        {
            code: "MCD17",
            name: "Medical Billing and Coding"
        },
        {
            code: "PBY-ECG17",
            name: "Phlebotomy and ECG Technician"
        },
        {
            code: "MAA-C",
            name: "Medical Assisting (Certificate Program)"
        },
        {
            code: "PLGC",
            name: "Paralegal Certificate Program"
        },
        {
            code: "PTH-2018",
            name: "Pharmacy Technician"
        },
        {
            code: "MHSM18",
            name: "Medical and Health Services Management (Bachelor)"
        },
        {
            code: "PTA-2018",
            name: "Pharmacy Technician (Associate Program)"
        }
    ],
    states: [
        {name: "Alabama", abbreviation: "AL"},
        {name: "Alaska", abbreviation: "AK"},
        {name: "Arizona", abbreviation: "AZ"},
        {name: "Arkansas", abbreviation: "AR"},
        {name: "California", abbreviation: "CA"},
        {name: "Colorado", abbreviation: "CO"},
        {name: "Connecticut", abbreviation: "CT"},
        {name: "Delaware", abbreviation: "DE"},
        {name: "District of Columbia", abbreviation: "DC"},
        {name: "Florida", abbreviation: "FL"},
        {name: "Georgia", abbreviation: "GA"},
        {name: "Hawaii", abbreviation: "HI"},
        {name: "Idaho", abbreviation: "ID"},
        {name: "Illinois", abbreviation: "IL"},
        {name: "Indiana", abbreviation: "IN"},
        {name: "Iowa", abbreviation: "IA"},
        {name: "Kansas", abbreviation: "KS"},
        {name: "Kentucky", abbreviation: "KY"},
        {name: "Lousiana", abbreviation: "LA"},
        {name: "Maine", abbreviation: "ME"},
        {name: "Maryland", abbreviation: "MD"},
        {name: "Massachusetts", abbreviation: "MA"},
        {name: "Michigan", abbreviation: "MI"},
        {name: "Minnesota", abbreviation: "MN"},
        {name: "Mississippi", abbreviation: "MS"},
        {name: "Missouri", abbreviation: "MO"},
        {name: "Montana", abbreviation: "MT"},
        {name: "Nebraska", abbreviation: "NE"},
        {name: "Nevada", abbreviation: "NV"},
        {name: "New Hampshire", abbreviation: "NH"},
        {name: "New Jersey", abbreviation: "NJ"},
        {name: "New Mexico", abbreviation: "NM"},
        {name: "New York", abbreviation: "NY"},
        {name: "North Carolina", abbreviation: "NC"},
        {name: "North Dakota", abbreviation: "ND"},
        {name: "Ohio", abbreviation: "OH"},
        {name: "Oklahoma", abbreviation: "OK"},
        {name: "Oregon", abbreviation: "OR"},
        {name: "Pennsylvania", abbreviation: "PA"},
        {name: "Rhode Island", abbreviation: "RI"},
        {name: "South Carolina", abbreviation: "SC"},
        {name: "South Dakota", abbreviation: "SD"},
        {name: "Tennessee", abbreviation: "TN"},
        {name: "Texas", abbreviation: "TX"},
        {name: "Utah", abbreviation: "UT"},
        {name: "Vermont", abbreviation: "VT"},
        {name: "Virginia", abbreviation: "VA"},
        {name: "Washington", abbreviation: "WA"},
        {name: "West Virginia", abbreviation: "WV"},
        {name: "Wisconsin", abbreviation: "WI"},
        {name: "Wyoming", abbreviation: "WY"}
    ],
    tabs: [
        {
            step: "1",
            title: "Application"
        },
        {
            step: "2",
            title: "Education Disclosure"
        },
        {
            step: "3",
            title: "Code of Computing"
        },
        {
            step: "4",
            title: "Felony Acknowledgement Form"
        },
        {
            step: "5",
            title: "Identity Authentication Form"
        },
        {
            step: "6",
            title: "Online Education Disclosure"
        },
        {
            step: "7",
            title: "CPA Eligibility Form"
        },
        {
            step: "8",
            title: "Technical Standards Form"
        },
        {
            step: "9",
            title: "HIM Technical Standards"
        },
        {
            step: "10",
            title: "Transferability of Credits Form"
        },
        {
            step: "11",
            title: "Disclosure of Info Mississippi"
        },
        {
            step: "12",
            title: "Finish & Submit"
        }
    ]
}
