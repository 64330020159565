import React, {Component} from "react"
import styles from "./Footer.module.css"

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>Bellus Academy</strong>
                    </p>
                    <p className={styles.text}>
                        POWAY, CA 13266 Poway Rd | Poway, CA 92064 | 858-748-1490
                    </p>
                    <p className={styles.text}>
                        EL CAJON, CA 1073 E. Main St | El Cajon, CA 92021 | 619-442-3407
                    </p>
                    <p className={styles.text}>
                        CHULA VISTA, CA 970 Broadway | Chula Vista, CA 91911 | 619-474-6607
                    </p>
                    <p className={styles.text}>
                        MANHATTAN, KS 1130 Westloop Pl | Manhattan, KS 66502 | 785-539-1837
                    </p>
                </div>
            </footer>
        )
    }
}
