import React, {Component} from "react"
import styles from "./RadioButton.module.css"
import {JsonDocument} from "Model"

interface Props {
    label: string

    group_name: string
    value: string
    json_data: JsonDocument
    onChange: (id, value) => void
}

// TODO
// Pure CSS approach to customize radio button
// https://codepen.io/manabox/pen/raQmpL

export class RadioButton extends Component<Props, {}> {
    public render() {
        const {value, json_data, group_name, onChange, label} = this.props
        const checked = value === json_data[group_name]
        const id = `${group_name}_${value}`
        return (
            <label className={styles.root} htmlFor={id}>
                <input
                    type="radio"
                    id={id}
                    name={group_name}
                    value={value}
                    checked={checked}
                    onChange={(event) => {
                        onChange(group_name, value)
                    }}
                />
                <p className={styles.text}>{label}</p>
            </label>
        )
    }
}
