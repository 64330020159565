import {validate} from "validate.js"

export function findErrors(
    json_data,
    rules
): {isValid: boolean; errors: {[key: string]: string} | null} {
    let r = validate(json_data, rules)

    if (!r) {
        return {isValid: true, errors: {}}
    }
    let r2 = {...r}
    for (let key in r2) {
        r2[key] = r2[key][0]
    }
    console.log(r2)
    return {isValid: false, errors: r2}
}

const camelToSpace = (str) => str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)

const iterateRules = (rules) => {
    const newError = {}
    for (let key in rules) {
        newError[key] = `${camelToSpace(key)} can't be empty`
    }
    return newError
}

export function findErrorUploadDocs(
    uploads,
    rules
): {isValid: boolean; errors: {[key: string]: string} | null} {
    if (!uploads) {
        return {isValid: false, errors: iterateRules(rules)}
    } else if (uploads.length === 0) {
        return {isValid: false, errors: iterateRules(rules)}
    }
    const arrayMapped = uploads.map(({type}) => ({[type]: type}))
    const ObjectRules = Object.assign({}, ...arrayMapped)

    let r = validate(ObjectRules, rules)
    if (!r) {
        return {isValid: true, errors: {}}
    }
    let r2 = {...r}
    for (let key in r2) {
        r2[key] = r2[key][0]
    }
    console.log(r2)
    return {isValid: false, errors: r2}
}
