import React, {Component} from "react"
import styles from "./Label.module.css"

interface Props {
    text: string
    required?: boolean
    children: JSX.Element | JSX.Element[]
    error?: string
    className?: string
}

export class Label extends Component<Props, {}> {
    public render() {
        const {children, text, error, required, className = ""} = this.props
        return (
            <label className={`${styles.root} ${className}`}>
                {text && (
                    <p className={styles.text}>
                        {text}
                        {required && <span className={styles.required}> *</span>}
                    </p>
                )}
                <div className={styles.wrapper}>{children}</div>
                {error && (
                    <div className={styles.errorWrapper}>
                        <p className={styles.errorText}>{error}</p>
                    </div>
                )}
            </label>
        )
    }
}
