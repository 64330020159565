import React, {Component} from "react"
import styles from "./SubMenu.module.css"
import {ReactComponent as CheckIcon} from "../img/check-mark.svg"
import {ReactComponent as MinusIcon} from "../img/minus-circle.svg"
import {ReactComponent as CheckCircleIcon} from "../img/check-circle.svg"
import {FormID, Screen} from "interfaces"
import {observable} from "mobx"

interface Props {
    items: {value: FormID; label: string}[]
    onClick: (id: FormID) => void
    selected: FormID
    checkedItems: FormID[]
    enabledItems: FormID[]
    screen: Screen
}

export class SubMenu extends Component<Props, {}> {
    // private onClosed() {
    //     const {onClose} = this.props
    //     if (window.innerWidth < 1400) {
    //         onClose()
    //     }
    // }
    @observable
    private wrapperRef: any

    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.onClick(event.target.value)
        }
    }

    public render() {
        const {items, onClick, selected, checkedItems, enabledItems, screen} = this.props

        return (
            <ul className={styles.root} ref={this.wrapperRef}>
                {items.map((item) => {
                    const form_id: FormID = item.value
                    if (!enabledItems.includes(form_id)) {
                        return null
                    }

                    let clazz = styles.button
                    if (item.value === selected) {
                        clazz = `${styles.button} ${styles.selected}`
                    }
                    const checked = checkedItems.includes(item.value)

                    return (
                        <li key={item.value} className={styles.item}>
                            <button
                                className={clazz}
                                onClick={() => {
                                    onClick(item.value)
                                }}>
                                <Icon checked={checked} screen={screen} />
                                <span>{item.label}</span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

interface IconProps {
    checked: boolean
    screen: Screen
}

function Icon({checked, screen}: IconProps) {
    const props = {width: "13", height: "13"}

    if (checked && screen === "tablet") {
        return <CheckIcon {...props} className={styles.icon} />
    }

    if (checked && screen === "desktop") {
        return <CheckIcon {...props} className={styles.icon} />
    }

    if (checked && screen === "mobile")
        return <CheckCircleIcon {...props} className={styles.circleIcon} />

    if (!checked) return <MinusIcon {...props} className={styles.iconMinus} />

    return null
}
