const refreshSignaturesOrInitials = (json_data, form_ids, ids) => {
    // console.log("@@@@@@@@@@@ refreshSignaturesOrInitials Pdf:", {
    //     form_ids,
    //     ids
    // })

    if (json_data.forms) {
        for (let j = 0; j < form_ids.length; j++) {
            const form = form_ids[j]
            delete json_data.forms[form]
        }
    }
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i]
        delete json_data[id]
        delete json_data[`${id}_date`]
    }
}

const REFILL_FORMS_IDS = {
    newEngland: {
        all: {
            forms: [
                "document_disclosure",
                "document_admissions",
                "high_school_transcript",
                "criminal_history_disclosure",
                "ferpa",
                "credit_evaluation",
                "enrollment_agreement_new_england",
                "enrollment_agreement_tampa_bay"
            ],
            ids: [
                "student_signature_disclosure",
                "student_signature_application_1",
                "student_signature_application_2",
                "student_signature_tr",
                "student_signature_ch",
                "student_signature_consent",
                "student_signature_credit",
                "student_signature_ean",
                "student_signature_ea"
            ]
        },
        document_disclosure: {
            forms: ["document_disclosure"],
            ids: ["student_signature_disclosure"], //, "parent_signature_disclosure"
            conditionId: "isDocumentDisclossureNewEnglandSubmitted"
        },
        document_admissions: {
            forms: ["document_admissions"],
            ids: ["student_signature_application_1", "student_signature_application_2"], // "parent_signature_application_1",
            conditionId: "isDocumentsNewEnglandSubmitted"
        },
        high_school_transcript: {
            forms: ["high_school_transcript"],
            ids: ["student_signature_tr"],
            conditionId: "isAdditionalDocumentsNewEnglandSubmitted"
        },
        criminal_history_disclosure: {
            forms: ["criminal_history_disclosure"],
            ids: ["student_signature_ch"],
            conditionId: "isAdditionalDocumentsNewEnglandSubmitted"
        },
        ferpa: {
            forms: ["ferpa"],
            ids: ["student_signature_consent"],
            conditionId: "isAdditionalDocumentsNewEnglandSubmitted"
        },
        credit_evaluation: {
            forms: ["credit_evaluation"],
            ids: ["student_signature_credit"], // ,"admission_advisor_signature_credit"
            conditionId: "isAdditionalDocumentsNewEnglandSubmitted"
        },
        enrollment_agreement_new_england: {
            forms: ["enrollment_agreement_new_england"],
            ids: ["student_signature_ean"], // "parent_signature_ean", "admission_advisor_signature_ean"
            conditionId: "isEnrollmentDocumentsNewEnglandSubmitted"
        }
    },
    tampaBay: {
        all: {
            forms: [
                "document_disclosure",
                "document_admissions",
                "high_school_transcript",
                "criminal_history_disclosure",
                "ferpa",
                "credit_evaluation",
                "enrollment_agreement_new_england",
                "enrollment_agreement_tampa_bay"
            ],
            ids: [
                "student_signature_disclosure",
                "student_signature_application_1",
                "student_signature_application_2",
                "student_signature_tr",
                "student_signature_ch",
                "student_signature_consent",
                "student_signature_credit",
                "student_signature_ean",
                "student_signature_ea"
            ]
        },
        document_admissions: {
            forms: ["document_admissions"],
            ids: ["student_signature_application_1", "student_signature_application_2"], // "parent_signature_application_1",
            conditionId: "isDocumentsTampaBaySubmitted"
        },
        high_school_transcript: {
            forms: ["high_school_transcript"],
            ids: ["student_signature_tr"],
            conditionId: "isAdditionalDocumentsTampaBaySubmitted"
        },
        criminal_history_disclosure: {
            forms: ["criminal_history_disclosure"],
            ids: ["student_signature_ch"],
            conditionId: "isAdditionalDocumentsTampaBaySubmitted"
        },
        ferpa: {
            forms: ["ferpa"],
            ids: ["student_signature_consent"],
            conditionId: "isAdditionalDocumentsTampaBaySubmitted"
        },
        credit_evaluation: {
            forms: ["credit_evaluation"],
            ids: ["student_signature_credit"], // ,"admission_advisor_signature_credit"
            conditionId: "isAdditionalDocumentsTampaBaySubmitted"
        },
        enrollment_agreement_tampa_bay: {
            forms: ["enrollment_agreement_tampa_bay"],
            ids: ["student_signature_ea"], // , "admission_advisor_signature_ea", "parent_signature_ea"
            conditionId: "isEnrollmentDocumentsTampaBaySubmitted"
        }
    }
}

const UPDATE_SIGNATURE_INTIAL_AND_DATE = {
    signature: "Click here to update signature and date",
    initial: "Click here to update initial and date"
}

const ALLOWED_UPLOAD_DOCS_FORMAT = {
    csv: "text/csv",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    gif: "image/gif",
    jpegOrjpg: "image/jpeg",
    odp: "application/vnd.oasis.opendocument.presentation",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    odt: "application/vnd.oasis.opendocument.text",
    png: "image/png",
    pdf: "application/pdf",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    rtf: "application/rtf",
    txt: "text/plain",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export {
    refreshSignaturesOrInitials,
    REFILL_FORMS_IDS,
    UPDATE_SIGNATURE_INTIAL_AND_DATE,
    ALLOWED_UPLOAD_DOCS_FORMAT
}
