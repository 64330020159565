import React, {Component} from "react"
import styles from "./input.module.css"
import {JsonDocument} from "../../../Model"

interface Props {
    id: string
    json_data: JsonDocument
    onChange: (id, value) => void
    disabled?: boolean
    placeholder?: string
    type?: "text" | "email" | "tel" | "date" | "password"
    errors?: {[key: string]: string}
    min?: string
    max?: string
}

export class Input extends Component<Props, {}> {
    public static defaultProps = {
        type: "text",
        errors: {}
    }

    public render() {
        const {id, json_data, onChange, type, placeholder, disabled, errors, min, max} = this.props

        const value = json_data[id] || ""
        const error = errors[id]
        return (
            <div>
                <input
                    id={id}
                    className={styles.input}
                    type={type}
                    value={value}
                    onChange={(event) => {
                        onChange(id, event.target.value)
                    }}
                    placeholder={placeholder}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
                {error && <p className={styles.error}>{error} </p>}
            </div>
        )
    }
}
