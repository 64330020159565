import React, {Component} from "react"
import styles from "./LabelRadio.module.css"

interface Props {
    text: string
    required?: boolean
    children: JSX.Element | JSX.Element[]
    error?: string
    className?: string
}

export class LabelRadio extends Component<Props, {}> {
    public render() {
        const {children, text, error, required, className = ""} = this.props
        return (
            <div className={`${styles.root} ${className}`}>
                <div className={styles.wrapper}>
                    {text && (
                        <p className={styles.text}>
                            {text}
                            {required && <span className={styles.required}> *</span>}
                        </p>
                    )}
                    <div className={styles.radio}>{children}</div>
                </div>
                {error && <p className={styles.error}>{error} </p>}
            </div>
        )
    }
}
