import React, {Component} from "react"
import styles from "./NavigationButton.module.css"
import {Model} from "Model"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import classNames from "classnames"
import {ReactComponent as Icon} from "./parts/img/icon.svg"
import {computed, observable} from "mobx"
import {
    isEnableSubmitButton,
    ENROLLMENT_APPLICATION,
    UPLOAD_DISABILITY_SUPPORTING_DOCUMENTS,
    hasAllDisabilityDocumentsRequired,
    stringErrors,
    APPLICATION_CAREER_INFORMATION
} from "helper/Methods"

interface Props {
    model: Model
    className?: string
    setTabForms?: Function
}

@observer
export class NavigationButton extends Component<Props, {}> {
    @computed
    private get nextButtonEnabled(): boolean {
        const model = this.props.model
        if (model.getNextFormId() === null && model.getFormId() === "submission") {
            return false
        }
        return true
    }

    @observable
    private submitButtonEnabled = true

    @computed
    private get saveButtonEnabled(): boolean {
        const model = this.props.model
        if (model.form.type === "pdf" && !model.iframeLoaded) {
            return false
        }
        return true
    }

    public render() {
        const {model, className} = this.props
        const {
            document: {json_data}
        } = model

        return (
            <div className={classNames(styles.root, className)}>
                <button
                    className={styles.button}
                    disabled={model.getPreviousFormId() === null}
                    onClick={() => {
                        const form_id: FormID = model.getPreviousFormId()
                        model.setFormId(form_id)
                    }}>
                    <Icon className={styles.icon} width="12" height="12" />
                    BACK
                </button>
                {/* json_data.disabilites === "yes" && !json_data.disabilitySelected */}
                {this.nextButtonEnabled && (
                    <>
                        {model.getFormId() === APPLICATION_CAREER_INFORMATION ? (
                            <button
                                className={classNames(styles.button, styles.buttonSave)}
                                onClick={async () => {
                                    const success = await model.saveCareerInformation()
                                    // model.dispatcher2.dispatch()
                                    // if (!success) {
                                    //     const errors = this.props.model.childErrors
                                    //     let str = ""
                                    //     if (errors) {
                                    //         str = stringErrors(errors)
                                    //     }
                                    //     alert(
                                    //         `You must have forgotten something. Please fix the errors\n${str}`
                                    //     )
                                    // }
                                    this.props.setTabForms()
                                }}>
                                SAVE
                            </button>
                        ) : model.getFormId() === ENROLLMENT_APPLICATION ? (
                            <button
                                className={classNames(styles.button, styles.buttonSave)}
                                onClick={async () => {
                                    const success = await model.saveEnrollmentApplication()
                                    // model.dispatcher2.dispatch()
                                    // if (!success) {
                                    //     const errors = this.props.model.childErrors
                                    //     let str = ""
                                    //     if (errors) {
                                    //         str = stringErrors(errors)
                                    //     }
                                    //     alert(
                                    //         `You must have forgotten something. Please fix the errors\n${str}`
                                    //     )
                                    // }
                                    this.props.setTabForms()
                                }}>
                                SAVE
                            </button>
                        ) : model.getFormId() === UPLOAD_DISABILITY_SUPPORTING_DOCUMENTS ? (
                            <button
                                className={classNames(styles.button, styles.buttonSave)}
                                onClick={async () => {
                                    if (
                                        !hasAllDisabilityDocumentsRequired(json_data.forms) &&
                                        json_data.disabilitySelected
                                    ) {
                                        alert(`You need to fill the previous forms first.`)
                                    } else {
                                        const success = await model.saveAndContinueWithReadyToEnroll()
                                        model.dispatcher2.dispatch()
                                    }
                                    this.props.setTabForms()
                                }}>
                                SAVE
                            </button>
                        ) : (
                            <button
                                className={classNames(styles.button, styles.buttonSave)}
                                onClick={async () => {
                                    const success = await model.save()
                                    // model.dispatcher2.dispatch()
                                    // if (!success) {
                                    //     const errors = this.props.model.childErrors
                                    //     let str = ""
                                    //     if (errors) {
                                    //         str = stringErrors(errors)
                                    //     }
                                    //     alert(
                                    //         `You must have forgotten something. Please fix the errors\n${str}`
                                    //     )
                                    // }
                                    this.props.setTabForms()
                                }}>
                                SAVE
                            </button>
                        )}
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "signature"
                                }
                            }}>
                            My Signature
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "initials"
                                }
                            }}>
                            My Initials
                        </button>
                        {/* <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "parent"
                                }
                            }}>
                            Parent Signature
                        </button> */}
                        {/* json_data.disabilites === "yes" && !json_data.disabilitySelected */}
                        {model.getFormId() === APPLICATION_CAREER_INFORMATION ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.saveCareerInformation()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        model.setFormId(form_id)
                                    } else {
                                        // alert("Please check errors")
                                    }
                                }}>
                                SAVE & CONTINUE{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === ENROLLMENT_APPLICATION ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.saveEnrollmentApplication()
                                    // model.dispatcher2.dispatch()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        if (form_id) {
                                            model.setFormId(form_id)
                                        }
                                    } else {
                                        // const errors = this.props.model.childErrors
                                        // let str = ""
                                        // if (errors) {
                                        //     str = stringErrors(errors)
                                        // }
                                        // alert(
                                        //     `You must have forgotten something. Please fix the errors\n${str}`
                                        // )
                                    }
                                }}>
                                SAVE & {!json_data.isApplicationSubmitted ? `SUBMIT` : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : model.getFormId() === UPLOAD_DISABILITY_SUPPORTING_DOCUMENTS ? (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    if (
                                        !hasAllDisabilityDocumentsRequired(json_data.forms) &&
                                        json_data.disabilitySelected
                                    ) {
                                        alert(`You need to fill the previous forms first.`)
                                    } else {
                                        const success = await model.saveAndContinueWithReadyToEnroll()
                                        model.dispatcher2.dispatch()
                                        const form_id: FormID = model.getNextFormId()
                                        if (form_id) {
                                            model.setFormId(form_id)
                                        }
                                        // if (success) {
                                        // } else {
                                        //     alert(
                                        //         "You must have forgotten something. Please fix the errors"
                                        //     )
                                        // }
                                    }
                                }}>
                                SAVE &{" "}
                                {json_data.disabilitySelected && !json_data.disabilitySubmitted
                                    ? `SUBMIT`
                                    : `CONTINUE`}{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        ) : (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                disabled={!this.saveButtonEnabled}
                                onClick={async () => {
                                    const success = await model.save()
                                    model.dispatcher2.dispatch()
                                    if (success) {
                                        const form_id: FormID = model.getNextFormId()
                                        if (form_id) {
                                            model.setFormId(form_id)
                                        }
                                    } else {
                                        // const errors = this.props.model.childErrors
                                        // let str = ""
                                        // if (errors) {
                                        //     str = stringErrors(errors)
                                        // }
                                        // alert(
                                        //     `You must have forgotten something. Please fix the errors\n${str}`
                                        // )
                                    }
                                }}>
                                SAVE & CONTINUE{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        )}
                    </>
                )}

                {!this.nextButtonEnabled && (
                    <button
                        className={classNames(styles.button, styles.buttonNext)}
                        disabled={
                            json_data && json_data.isSubmitted
                                ? true
                                : isEnableSubmitButton(
                                      json_data.forms || [],
                                      json_data.campus || "",
                                      this.props.model.campusesInfo || [],
                                      json_data.program || ""
                                  )
                        }
                        onClick={async () => {
                            this.submitButtonEnabled = false
                            await model.submit()
                            // if (!success) {
                            //     alert("Can not submit")
                            //     return
                            // }
                            this.forceUpdate()
                        }}>
                        SUBMIT <Icon className={styles.icon} width="12" height="12" />
                    </button>
                )}
            </div>
        )
    }
}
