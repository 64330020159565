import React, {Component} from "react"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrorUploadDocs} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import styles from "./UploadDisabilityDocs.module.css"
import {UploadDisabilityInput} from "./upload-disability-input"

interface Props {
    model: Model
}

// const mandatory = {
//     presence: {allowEmpty: false}
// }

const rules = {
    supportingDisabilityDoc: {
        presence: {allowEmpty: false, message: "^Supporting disability doc can't be empty"}
    }
}

@observer
export class UploadDisabilityDocs extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate supporting disability documentation")
        const {isValid, errors} = findErrorUploadDocs(this.json_data.uploadDisabilities, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        if (this.json_data.isSubmitted) {
            delete this.json_data.isSubmitted
        }
        this.json_data[id] = value
        this.forceUpdate()
    }

    public render() {
        const {model} = this.props
        const uploads = this.props.model.document.json_data.uploadDisabilities || []
        const {errors} = this

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>Upload Documents</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>
                    Upload supporting documentation
                </h3>
                <p className={styles.advice}>
                    ** The <strong>documents</strong> you are submitting will be reviewed by Bellus
                    Academy committee.
                </p>
                <p className={styles.working}>
                    <strong>To upload documents</strong>, you can scan them and save the files onto
                    our computer OR take a clear picture using your smartphone's camera.{" "}
                    <strong>Please attempt to save them under your complete name</strong>.
                </p>
                <div style={{marginTop: "60px"}}></div>
                <UploadDisabilityInput
                    model={model}
                    title={`Disability Documentation`}
                    btnId={`supportingDisabilityDoc`}
                    uploads={uploads}
                />
                {errors && <p className={styles.error}>{errors["supportingDisabilityDoc"]} </p>}
            </div>
        )
    }
}
