import React, {Component} from "react"
import styles from "./Main.module.css"
import {observer} from "mobx-react"
import {observable} from "mobx"

import {Model} from "../../Model"

interface Props {
    model: Model
}

@observer
export class Main extends Component<Props, {}> {
    @observable
    private index = 0

    private iframe: HTMLIFrameElement

    public render() {
        const model = this.props.model
        const token = model.gate.getToken()

        if (!model.user) {
            return <h1>Error. No token</h1>
        }

        const url = `${process.env.REACT_APP_PDF_DOMAIN}?form_id=${model.form.form_id}&token=${token}&user_id=${model.user.id}`

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>{model.form.title}</h2>

                <iframe
                    id="admissions-pdf"
                    title="PDF Preview"
                    src={url}
                    frameBorder={0}
                    width="100%"
                    height="100%"
                    className={styles.iframe}
                    allowFullScreen
                    ref={(f) => {
                        this.iframe = f as HTMLIFrameElement
                    }}
                />
            </div>
        )
    }
}
