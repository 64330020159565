import React, {Component} from "react"
import {CheckBox} from "components/inputs/CheckBox"
import styles from "./Disclosure.module.css"
import {Model, JsonDocument} from "Model"

interface Props {
    model: Model
}

export class Disclosure extends Component<Props, {}> {
    private json_data: JsonDocument = {}
    private CHECKED = "yes"

    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onModel = this.onModel.bind(this)
        this.json_data = props.model.document.json_data
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    public render() {
        const r = {onChange: this.onChange, json_data: this.json_data}
        const {json_data, CHECKED} = this
        const {model} = this.props
        return (
            <div className={styles.modalWrapper}>
                <div className={styles.modalDialog}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <h2> Consumer Disclosure </h2>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.scroll}>
                                <h3>Consumer Disclosure and Consent for Electronic Delivery</h3>
                                <p>
                                    The document sender from your college has sent you a document to
                                    sign through the Klass App’s electronic signature system. In
                                    compliance with the U.S. Electronic Signatures in Global and
                                    National Commerce Act, known as the E-Sign Act, Klass App is
                                    providing you with the following information regarding your
                                    decision to receive information in electronic form. You can
                                    record your consent to the E-SIGN disclosure by placing a
                                    checkmark in the box below and click “Continue”. By checking the
                                    boxes you consent to the electronic delivery of the disclosures,
                                    agreements, change notices, terms and conditions and any other
                                    documents. You also agree that we do not need to provide you
                                    with additional paper (non- electronic) copies of the
                                    disclosures, agreements, change notices, terms and conditions
                                    and any other documents, unless specifically requested. Once you
                                    consent to the disclosures, you may request paper copies, which
                                    may result in a fee.
                                </p>
                                <h3>Electronic Delivery</h3>
                                <p>
                                    In conjunction with this document signature request and future
                                    document signature requests, Klass App will send you document
                                    notifications, records, notices, disclosures, acknowledgements,
                                    and other communications in electronic form and not printed on
                                    paper.
                                </p>
                                <h3>System Requirements to Access Information</h3>
                                <p></p>
                                The following are the hardware and software requirements for access
                                to and retention of electronic records:
                                <ul>
                                    <li>
                                        Operating Systems: Windows 2000, Windows XP, Windows Vista,
                                        Mac OSX
                                    </li>
                                    <li>
                                        Browsers: Internet Explorer 8 or above, Mozilla Firefox 3 or
                                        above, Google Chrome 4 or above, Safari 3 or above
                                    </li>
                                    <li>Screen Resolution: 800 x 600 minimum</li>
                                    <li>Smartphone or Tablets: iOS and Android devices only</li>
                                    <li>
                                        Mobile device operating system: iOS 6.0 or above, Android
                                        4.0 or above
                                    </li>
                                </ul>
                                <p></p>
                                <p>
                                    After signing a document on Klass App, you have the option to
                                    download and print a paper copy of the document, and no fee is
                                    charged for this copy. In addition, you may login to your
                                    college’s App, click on MyKlass then MyDocs, to access and
                                    download your document for a limited time into the future, for
                                    as long as your account is active with your college. Prior to
                                    signing, Klass App does not provide an option for you to receive
                                    the document in paper or non-electronic form.
                                </p>
                                <h3>
                                    Withdrawal of Electronic Acceptance of Disclosures and Notices
                                </h3>
                                <p>
                                    You have the right to decline consent to receiving documents and
                                    records in electronic format. You may decline to receive this
                                    document by abandoning this App or website session, and you may
                                    decline to receive future documents and records electronically
                                    from your college by emailing to&nbsp;
                                    <strong>
                                        <a href="mailto:support@klassapp.com">
                                            support@klassapp.com
                                        </a>
                                    </strong>
                                    &nbsp; with your request. However, if you decline to receive
                                    documents and records in electronic format, as a consequence you
                                    may not be able to conduct business with your college. If you
                                    sign this document using Klass App, you are providing
                                    affirmative consent for the use of electronic records for this
                                    particular transaction only and demonstrating that you can
                                    access information in the electronic form that will be used to
                                    provide the information that is the subject of this consent.
                                </p>
                                <h3>How to Update Your Records</h3>
                                <p>
                                    It is your responsibility to provide us with true, accurate and
                                    complete e-mail address, contact, and other information related
                                    to this Disclosure and your Account(s), and to maintain and
                                    update promptly any changes in this information. To change the
                                    email address where you would like to receive electronic
                                    communications, send an email to&nbsp;
                                    <strong>
                                        <a href="mailto:support@klassapp.com">
                                            support@klassapp.com
                                        </a>
                                    </strong>
                                    &nbsp; containing both your old and new email address. Any other
                                    changes should be directed to your college’s registrar or
                                    student services department.
                                </p>
                            </div>
                        </div>
                        <div className={styles.agreements}>
                            <CheckBox
                                label=" I have read and agree to the Terms."
                                value="no"
                                id={"agreement"}
                                {...r}
                            />
                        </div>
                        {json_data.agreement && json_data.agreement === CHECKED && (
                            <div className={styles.modalFooter}>
                                <button
                                    type="button"
                                    className={`${styles.btn} ${styles.btnPrimary}`}
                                    onClick={async () => {
                                        await model.saveAgreement()
                                    }}>
                                    Accept
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
