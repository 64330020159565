import React, {Component} from "react"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import styles from "./UploadDisabilityInput.module.css"
import {ALLOWED_UPLOAD_DOCS_FORMAT} from "lib/functions/helper"

interface Props {
    title: string
    btnId: string
    uploads: any
    model: Model
}

@observer
export class UploadDisabilityInput extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private staticFile = {
        id: -1,
        name: "",
        url: ""
    }

    private json_data: JsonDocument = {}

    private CONSTANT_STATIC_PATH = "/uploads"

    private FILENAME_DASH = "_"
    private FILENAME_DOT = "."
    private START_INDEX_FILENAME = 0

    constructor(props) {
        super(props)
        this.json_data = props.model.document.json_data

        if (this.json_data.uploadDisabilities && this.json_data.uploadDisabilities.length > 0) {
            const signedUrl = this.json_data.uploadDocsSignedUrls
            const findStaticFile = this.json_data.uploadDisabilities.find(
                ({type}) => type === this.props.btnId
            )
            if (findStaticFile) {
                this.staticFile = {...findStaticFile}
                this.staticFile.url = `${findStaticFile.path}${signedUrl[findStaticFile.type]}`
                this.staticFile.name = this.cutFilename(findStaticFile.name)
            }
        }
        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
    }

    public async componentWillUnmount() {
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private onHandleChangeButtonUpload = async (id: string, {target: {files}}) => {
        console.log("UploadDisabiltyInput:", {id, files})
        if (files.length > 0) {
            const [aFile] = files
            const response = await this.props.model.uploadDocument(aFile, id)
            if (response.success) {
                if (this.json_data.isSubmitted) {
                    delete this.json_data.isSubmitted
                }
                const uploadDoc = response.data.upload
                if (!this.json_data.uploadDocsSignedUrls) {
                    this.json_data.uploadDocsSignedUrls = {
                        [id]: response.data.signedUrl
                    }
                } else {
                    this.json_data.uploadDocsSignedUrls[id] = response.data.signedUrl
                }
                this.replaceCurrentUploadFIle(id, uploadDoc)
            }
        }
        this.forceUpdate()
    }

    private replaceLastIndexOf = (aPath) =>
        aPath.substring(aPath.lastIndexOf(this.CONSTANT_STATIC_PATH), aPath.length)

    private cutFilename = (fileName) => {
        if (fileName.lastIndexOf(this.FILENAME_DOT) > -1) {
            const lastIndexOfDash = fileName.lastIndexOf(this.FILENAME_DASH)
            const lastIndexOfDot = fileName.lastIndexOf(this.FILENAME_DOT)
            const newName = `${fileName.substring(
                this.START_INDEX_FILENAME,
                lastIndexOfDash
            )}${fileName.substring(lastIndexOfDot, fileName.length)}`
            return newName
        }
        return fileName
    }

    private replaceCurrentUploadFIle = (id, file) => {
        const uploads = this.props.uploads
        if (uploads.length > 0) {
            const foundIndexUpload = uploads.findIndex((aFile) => aFile.type === id)
            const signedUrl = this.json_data.uploadDocsSignedUrls
            if (foundIndexUpload > -1) {
                uploads[foundIndexUpload] = {...file}
                this.staticFile = {...file}
                this.staticFile.url = `${uploads[foundIndexUpload].path}${
                    signedUrl[uploads[foundIndexUpload].type]
                }`
                this.staticFile.name = this.cutFilename(uploads[foundIndexUpload].name)
            } else {
                uploads.push(file)
                const foundFile = uploads.find((aFile) => aFile.type === id)
                this.staticFile = {...foundFile}
                this.staticFile.url = `${foundFile.path}${signedUrl[foundFile.type]}`
                this.staticFile.name = this.cutFilename(foundFile.name)
            }
        } else {
            uploads.push(file)
            const [first] = uploads
            const signedUrl = this.json_data.uploadDocsSignedUrls
            this.staticFile = {...file}
            this.staticFile.url = `${first.path}${signedUrl[first.type]}`
            this.staticFile.name = this.cutFilename(file.name)
        }
        this.json_data.uploadDisabilities = uploads
    }
    private showDocument = () => {
        const uploads = this.props.uploads
        if (uploads.length > 0) {
            const foundIndex = this.props.uploads.findIndex(
                (aFile) => aFile.type === this.props.btnId
            )
            if (foundIndex > -1) {
                return true
            }
            return false
        }
        return false
    }

    public render() {
        const {title, btnId} = this.props

        const {onHandleChangeButtonUpload, showDocument, staticFile} = this
        const {
            csv,
            doc,
            docx,
            gif,
            jpegOrjpg,
            odp,
            ods,
            odt,
            png,
            pdf,
            ppt,
            pptx,
            rtf,
            txt,
            xls,
            xlsx
        } = ALLOWED_UPLOAD_DOCS_FORMAT
        return (
            <div className={styles.form}>
                <div className={styles.root}>
                    <input
                        type="file"
                        accept={`${csv}, ${doc}, ${docx}, ${gif}, ${jpegOrjpg}, ${odp}, ${ods}, ${odt}, ${png}, ${pdf}, ${ppt}, ${pptx}, ${rtf}, ${txt}, ${xls}, ${xlsx}`}
                        id={btnId}
                        onChange={(e) => {
                            onHandleChangeButtonUpload(btnId, e)
                        }}
                        hidden
                    />
                    <label htmlFor={btnId}>{title}</label>
                </div>
                {showDocument() && (
                    <div className={styles.fileImg}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{staticFile.name}</td>
                                    <td>
                                        <a
                                            href={staticFile.url}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="fas fa-eye fa-lg"></i>
                                            {/* <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 16 16"
                                            className="bi bi-file-earmark-binary"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
                                            <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5zm-3.474 8.59c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a6.749 6.749 0 0 1-.006-.252zm.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145c.003.079.005.164.005.249 0 1.052-.29 1.614-.829 1.614zm5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z" />
                                        </svg> */}
                                        </a>
                                    </td>
                                    <td
                                        onClick={async () => {
                                            const model = this.props.model
                                            const responseDocument = await model.deleteSupportedDocument(
                                                this.staticFile.id
                                            )
                                            if (responseDocument.success) {
                                                const filteredUploadedDocs = this.json_data.uploadDisabilities.filter(
                                                    (uploadDoc) =>
                                                        uploadDoc.id !== this.staticFile.id
                                                )
                                                this.json_data.uploadDisabilities = filteredUploadedDocs
                                                await model.updateDocumentUpload()
                                            }
                                            this.forceUpdate()
                                        }}>
                                        <i className="fas fa-trash-alt fa-lg"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )
    }
}
