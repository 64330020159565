import React, {Component} from "react"
import styles from "./MenuButton.module.css"

interface Props {
    onClick: (e?) => void
}

export class MenuButton extends Component<Props, {}> {
    public render() {
        return <button className={styles.root} onClick={this.props.onClick}></button>
    }
}
